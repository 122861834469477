import CountUp from 'countup.js';
import ScrollTrigger from 'scrolltrigger-classes';
// import Glide from '@glidejs/glide';


function animateStats(index) {
	// console.log('index', index);

	if (index == 1) {
		const count1 = new CountUp("count1", 0, 23);
		// const count1 = new CountUp("count1", 24.02, 143);
		if (!count1.error) {
			count1.start();
		} else {
			console.error(count1.error);
		}
	} else if (index == 2) {
		const count2 = new CountUp("count2", 0, 143);
		// const count2 = new CountUp("count2", 24.02, 242);
		if (!count2.error) {
			count2.start();
		} else {
			console.error(count2.error);
		}
	} else if (index == 3) {
		const count3 = new CountUp("count3", 0, 242);
		if (!count3.error) {
			count3.start();
		} else {
			console.error(count3.error);
		}
	} else if (index == 4) {
		const options = {
			useEasing: true,
			useGrouping: true,
			separator: ',',
			decimal: '.',
			suffix: '%'
		};
		const count4 = new CountUp("count4", 0, 97, 0, 2.5, options);
		if (!count4.error) {
			count4.start();
		} else {
			console.error(count4.error);
		}
	}
}

window.animateStats = animateStats;

document.addEventListener('DOMContentLoaded', function () {
	var trigger = new ScrollTrigger();
});

// new Glide('.glide', {
// 	type: 'carousel',
// 	perView: 4,
// 	gap: 10,
// 	autoplay: 1000,
// 	breakpoints: {
// 		1024: {
// 			perView: 2
// 		},
// 		600: {
// 			perView: 2
// 		}
// 	}
// }).mount();