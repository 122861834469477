const video = document.querySelector('video');
const videoWrapper = document.querySelector('.video');



if (localStorage.getItem("video_played") === "true") {
	videoWrapper.style.display = 'none';
}


video.addEventListener('ended', (e) => {
	console.log('fadeout');
	videoWrapper.classList.add('fadeOut');
	localStorage.setItem("video_played", "true");
	setTimeout(() => {
		videoWrapper.style.display = 'none';
	}, 1000);
});